/* PC固有のBaseスタイルがあればこちらに記載する */
$common-fonts: verdana, helvetica, 'helvetica neue', arial, '游ゴシック体',
  yugothic, '游ゴシック medium', 'yu gothic medium', '游ゴシック', 'yu gothic',
  'メイリオ', meiryo, 'ｍｓ ｐゴシック', 'hiragino kaku gothic pron',
  'ヒラギノ角ゴ pron w3', sans-serif !important;

body,
input {
  font-family: $common-fonts;
}

body {
  color: #222;
  background-color: #f0f9ff;
}

a {
  color: inherit;
}
