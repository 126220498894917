/* PC, SP共通のスタイルがあればこちらに記載する */

/* reset */
@import './Reset.scss';

*,
.slick-slider {
  box-sizing: border-box;
}

.btnEntry,
a {
  text-decoration: none;
}
