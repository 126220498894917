/* Slider */
.slick-slider {
  position: relative;
  box-sizing: border-box;

  display: block;
  -ms-touch-action: pan-y;
  touch-action: pan-y;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -khtml-user-select: none;
  user-select: none;

  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;

  display: block;
  padding: 0 22%;

  margin: 0;
  overflow: hidden;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;

  display: block;
  margin-right: auto;
  margin-left: auto;
}

.slick-track::before,
.slick-track::after {
  display: table;

  content: '';
}

.slick-track::after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display: none;
  float: left;

  height: 100%;
  min-height: 1px;
}

[dir='rtl'] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;

  height: auto;

  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: block;
  width: 48px;
  height: 75px;
  margin: auto 0;
  overflow: hidden;
  color: rgba(0 0 0 / 0%);
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;
  border: 1px #99cf16 solid;
  outline: none;
  box-shadow: none !important;
}

.slick-prev:hover::before,
.slick-prev:focus::before,
.slick-next:hover::before,
.slick-next:focus::before {
  opacity: 1;
}

.slick-prev.slick-disabled::before,
.slick-next.slick-disabled::before {
  opacity: 0.25;
}

.slick-prev::before,
.slick-next::before {
  font-family: slick, sans-serif;
  font-size: 20px;
  line-height: 1;
  color: white;

  opacity: 0.75;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  left: 0;
  background: url('https://doda.jp/cmn_web/img/sp/icon_prevarrow.png')
    rgba(153 207 22 / 80%) no-repeat 45% 50%;
  background-size: auto 20px;
  border-left: none;
  border-radius: 0 4px 4px 0;
}

.slick-next {
  right: 0;
  background: url('https://doda.jp/cmn_web/img/sp/icon_nextarrow.png')
    rgba(153 207 22 / 80%) no-repeat 55% 50%;
  background-size: auto 20px;
  border-right: none;
  border-radius: 4px 0 0 4px;
}

/* Dots */
.slick-dots {
  position: absolute;
  bottom: -30px;
  width: 100%;
  padding: 0;
  margin: 0;
  text-align: center;
  list-style: none;
}

.slick-dots li {
  position: relative;
  display: inline-block;
  padding: 0;
}

.slick-dots li button {
  display: block;
  width: 20px;
  height: 20px;
  padding: 5px;
  font-size: 0;
  line-height: 0;
  color: transparent;
  cursor: pointer;
  background: transparent;
  border: 0;
  outline: none;
}

.slick-dots li button::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  font-size: 28px;
  line-height: 28px;
  color: #000;
  text-align: center;
  content: '\2022';
  opacity: 0.25;
}

.slick-dots li.slick-active button::before {
  color: #000;
  opacity: 0.75;
}
